* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    --color-header: #293132;
    --color-2: #452103;
    --color-3: #00A878;
    --color-4: #7ebdc2;
    --color-main:#547AA5;
}

header {
    background-color: var(--color-header);
    color: white;
    font-size: 2rem;
    padding: .25em 1em;
    display: flex;
}

button {
    background-color: var(--color-main);
    border: none;
    border-radius: 10000px;
    padding: .25em 2em;
    display: flex;
    align-items: center;
}

button svg.svg-inline--fa {
    font-size: 1.5em;
    margin-right: .5em;
}

button:hover {
    opacity: 0.75;
}

button:active {
    opacity: 0.5;
}

button.large {
    font-size: 2rem;
}

.spacer {
    flex-grow: 1
}

body > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100vh;
}

.center-box {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

#search-query {
   font-size: 2rem;
   margin-bottom: .5em;
   width: 100%;
}

.container {
    width: 80%;
    max-width: 1024px;;
    margin: 1em auto;
    display: block;
 
}

.row {
    display: flex;
}

.statuses {
    list-style: none;
}

.results {
    list-style: none;
}

input {
    padding: .25em .5em;
    border-radius: 10000px;
    border: solid 3px black;
}

.results {
    font-size: 1.5rem;
}
.results li {
    padding-bottom: .5em;
    display: flex;
}
.info-icon {
    margin-left: auto;
    padding: .25em;
    color: white;
}
button.info-icon svg.svg-inline--fa {
    margin-right: 1px;
}

.grid-row {
    display: flex;
}
.grid-cell {
    width: 1em;
    height: 1em;
}

.nav-btn {
    color: white;
}